import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Scrollspy from 'react-scrollspy';
import externallink from '@static/icons/externallink.svg';
import { Container } from '@components/global';
import ExternalLink from '@common/ExternalLink';
import {
  Nav,
  NavItem,
  Brand,
  StyledContainer,
  NavListWrapper,
  MobileMenu,
  Mobile,
} from './style';

import { ReactComponent as MenuIcon } from '@static/icons/menu.svg';

const NAV_ITEMS = [
  { name: 'Live', link: 'https://calendar.google.com/calendar/embed?src=ptgvo9sbq2b7ocmf6biujg7n14%40group.calendar.google.com&ctz=Asia%2FTokyo' },
  { name: 'Profile', link: 'https://note.com/fukudahitomi/n/nf3b581b4b225' },
  { name: 'Discography', link: '#discography' },
  { name: 'Blog', link: 'https://note.com/fukudahitomi' },
  { name: 'Contact', link: 'https://docs.google.com/forms/d/e/1FAIpQLSf24lpdSrklS-yWT6hlEkMnnlmsOggKNQ_UYs4OsT2pSPxxVg/viewform' },
];

class Navbar extends Component {
  state = {
    mobileMenuOpen: false,
  };

  toggleMobileMenu = () => {
    this.setState(prevState => ({ mobileMenuOpen: !prevState.mobileMenuOpen }));
  };

  closeMobileMenu = () => {
    if (this.state.mobileMenuOpen) {
      this.setState({ mobileMenuOpen: false });
    }
  };

  getNavAnchorLink = item => (
    item.link.startsWith('#') ?
      <AnchorLink href={item.link} onClick={this.closeMobileMenu}>
        {item.name}
      </AnchorLink>
      :
      <ExternalLink href={item.link} onClick={this.closeMobileMenu}>
        {item.name} <img src={externallink} width="15px" alt="icon" />
      </ExternalLink>
  );

  getNavList = ({ mobile = false }) => (
    <NavListWrapper mobile={mobile}>
      <Scrollspy
        items={NAV_ITEMS.map(item => item.name.toLowerCase())}
        currentClassName="active"
        mobile={mobile}
        offset={-64}
      >
        {NAV_ITEMS.map(navItem => (
          <NavItem key={navItem}>{this.getNavAnchorLink(navItem)}</NavItem>
        ))}
      </Scrollspy>
    </NavListWrapper>
  );

  render() {
    const { mobileMenuOpen } = this.state;

    return (
      <Nav {...this.props}>
        <StyledContainer>
          <Brand>Fukuda Hitomi</Brand>
          <Mobile>
            <button onClick={this.toggleMobileMenu} style={{ color: 'black' }}>
              <MenuIcon />
            </button>
          </Mobile>

          <Mobile hide>{this.getNavList({})}</Mobile>
        </StyledContainer>
        <Mobile>
          {mobileMenuOpen && (
            <MobileMenu>
              <Container>{this.getNavList({ mobile: true })}</Container>
            </MobileMenu>
          )}
        </Mobile>
      </Nav>
    );
  }
}

export default Navbar;
